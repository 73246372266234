<template>
    <NuxtLink
        v-if="entry && title && to"
        :to="to"
        class="card"
        :class="{
            'card--inline': isInline,
            'card--square': !isInline
        }"
        :data-has-access="userHasAccess"
    >
        <div class="card__container">
            <div class="card__body">
                <div class="card__content">
                    <h3
                        v-if="title"
                        class="card__title"
                    >
                        {{ title }}
                    </h3>

                    <div
                        v-if="!!$slots.description"
                        class="card__description"
                    >
                        <slot name="description" />
                    </div>
                </div>

                <div
                    v-if="!!$slots.meta"
                    class="card__meta"
                >
                    <slot name="meta" />
                </div>
            </div>

            <div
                v-if="image || !!$slots.image"
                class="card__image"
            >
                <div
                    v-if="!userHasAccess"
                    class="card__lock"
                >
                    <BaseIcon icon="lock" />
                </div>
                <slot name="image">
                    <BaseImage
                        :image="image"
                        :title="image.title"
                        :width="image.width"
                        :height="image.height"
                        sizes="(max-width: 992px) 248px, 410px"
                    />
                </slot>
            </div>
        </div>
    </NuxtLink>
</template>

<script setup>
import BaseImage from '@/components/BaseImage/BaseImage.vue';
import { toRefs, computed } from 'vue';
import { useAuthStore } from '~/store/auth';
import { hasAccess } from '~/utils/access';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    uri: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    isInline: {
        type: Boolean,
        default: false
    }
});

const { featuredImage } = toRefs(props.entry);
const image = featuredImage?.value?.[0];

const userHasAccess = computed(() => {
    const user = useAuthStore().user;

    return hasAccess(props.entry, user?.contentPermissions);
});

const to = computed(() => {
    if (props.uri) {
        return props.uri;
    }

    if (props.entry?.uri) {
        return `/${props.entry.uri}`;
    }

    return null;
});

const title = computed(() => {
    if (props.title) {
        return props.title;
    }

    if (props.entry?.title) {
        return props.entry.title;
    }

    return null;
});
</script>

<style lang="less" src="./BaseCard.less" />
